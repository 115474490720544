import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    pageData: {},
    route: {},
    apiRoute: '',
    fsData: {},
    alData: {},
    clData: {},
    blData: {},
    lang: 'id',
    notification: {}
  },
  getters: {
    currentPageData: state => state.pageData,
    currentRoute: state => state.route,
    currentApiRoute: state => state.apiRoute,
    currentFsData: state => state.fsData,
    currentAlData: state => state.alData,
    currentClData: state => state.clData,
    currentBlData: state => state.clData,
    currentLang: state => state.lang,
    currentNotification: state => state.notification
  },
  mutations: {
    GET_PAGE_DATA: (state, data) => {
      state.pageData = data
    },
    SET_ROUTE: (state, route) => {
      state.route = route
    },
    SET_API_ROUTE: (state, apiRoute) => {
      state.apiRoute = apiRoute
    },
    GET_FS_DATA: (state, data) => {
      state.fsData = data
    },
    GET_AL_DATA: (state, data) => {
      state.alData = data
    },
    GET_CL_DATA: (state, data) => {
      state.clData = data
    },
    GET_BL_DATA: (state, data) => {
      state.blData = data
    },
    SET_LANG: (state, data) => {
      state.lang = data
    },
    NOTIFICATION_MESSAGE: (state, data) => {
      state.notification = data
    }
  },
  actions: {
    getCurrPageData ({ commit, state }) {
      axios.get(process.env.VUE_APP_API_PATH + 'pages/' + state.apiRoute).then(res => {
        commit('GET_PAGE_DATA', res.data)
      })
    },
    getFsData ({ commit, state }) {
      const postFilter = JSON.parse(localStorage.getItem('post_filter'))
      let query = (postFilter.count) ? '?count=' + postFilter.count : '?count=0'
      query += (postFilter.category !== 'all' && postFilter.category !== null) ? '&cat=' + postFilter.category : ''
      axios.get(process.env.VUE_APP_API_PATH + 'posts/' + query).then(res => {
        commit('GET_FS_DATA', res.data)
      })
    },
    getAlData ({ commit, state }) {
      const postFilter = JSON.parse(localStorage.getItem('post_filter'))
      let query = '?hl=active'
      query += (postFilter.count) ? '&count=' + postFilter.count : '&count=0'
      query += (postFilter.category !== 'all' && postFilter.category !== null) ? '&cat=' + postFilter.category : ''
      axios.get(process.env.VUE_APP_API_PATH + 'posts/' + query).then(res => {
        commit('GET_AL_DATA', res.data)
      })
    },
    getClData ({ commit, state }) {
      const postFilter = JSON.parse(localStorage.getItem('list_filter'))
      let query = (postFilter.count) ? '?count=' + postFilter.count : '?count=0'
      query += (postFilter.page >= 1) ? '&page=' + postFilter.page : ''
      axios.get(process.env.VUE_APP_API_PATH + 'posts/' + query).then(res => {
        commit('GET_CL_DATA', res.data)
      })
    },
    getBlData ({ commit, state }) {
      const businessFilter = JSON.parse(localStorage.getItem('list_filter'))
      let query = (businessFilter.search) ? '?s=' + businessFilter.search : '?s='
      query += (businessFilter.page >= 1) ? '&page=' + businessFilter.page : ''
      axios.get(process.env.VUE_APP_API_PATH + 'business/' + query).then(res => {
        commit('GET_CL_DATA', res.data)
      })
    },
    sendContactMail ({ commit, state }) {
      const contactData = JSON.parse(localStorage.getItem('contact_data'))
      console.log(process.env.VUE_APP_API_PATH + 'send-mail')
      axios.post(process.env.VUE_APP_API_PATH + 'send-mail', contactData).then(res => {
        commit('NOTIFICATION_MESSAGE', res.data)
        setTimeout(() => {
          commit('NOTIFICATION_MESSAGE', {})
        }, 5000)
      }).catch(err => {
        console.log(err)
      })
    }
  }
})
