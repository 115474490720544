<template>
  <div></div>
</template>

<script>
export default {
  name: 'InsertScript',
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  mounted () {
    const d = document
    const s = 'script'
    const i = d.createElement('script')
    const e = d.getElementsByTagName(s)[0]
    i.async = 1
    i.charset = 'UTF-8'
    i.src = this.src
    e.parentNode.insertBefore(i, e)
  }
}
</script>
