<template>
  <loading  v-bind:class = "(pageLoad)?'aktive':''" />
  <div id="ktl-app">
      <nav-bar :navigation="navData" :mainLogo="mainLogoData"/>
      <router-view :content="pageData" :component="componentData" :order="componentOrder"/>
      <div class="logo-wa">
            <a href="https://wa.me/628129955321" target="_blank">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/768px-WhatsApp.svg.png"/>
            </a>
          </div>
      <site-footer :general="generalData"/>
      <notification />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import SiteFooter from '@/components/SiteFooter'
import Notification from '@/components/Notification'
import Loading from '@/components/Loading'

export default {
  name: 'App',
  data: function () {
    return {
      dataBool: true
    }
  },
  components: {
    Loading,
    NavBar,
    SiteFooter,
    Notification
  },
  methods: {
    loadFun () {
      if (this.$store.getters.currentPageData.pages !== undefined) {
        setTimeout(() => {
          this.dataBool = false
        }, 1000)
      }
      return this.dataBool
    }
  },
  computed: {
    pageLoad () {
      this.loadFun()
      return this.dataBool
    },
    pageData () {
      return this.$store.getters.currentPageData.pages
    },
    componentData () {
      return this.$store.getters.currentPageData.components
    },
    navData () {
      return this.$store.getters.currentPageData.navigation
    },
    generalData () {
      return this.$store.getters.currentPageData.general
    },
    mainLogoData () {
      const generalData = this.$store.getters.currentPageData.general
      let generalArray = []
      let imgData = {}
      if (generalData !== undefined) generalArray = JSON.parse(JSON.stringify(generalData))
      generalArray.forEach((item) => {
        if (item.slug === 'main-logo') imgData = item
      })
      return imgData
    },
    componentOrder () {
      const pageData = this.$store.getters.currentPageData.pages
      let dataArray = []
      if (pageData !== undefined) dataArray = JSON.parse(JSON.stringify(pageData))
      const components = dataArray.components
      let order = []
      if (components !== undefined) order = components.split(',')
      return order
    }
  },
  mounted () {
    this.$store.dispatch('getCurrPageData')
  },
  updated () {
    this.dataLoad = false
  }
}
</script>
