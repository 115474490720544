<template>
  <div class="article-list">
    <div class="my-20">
      <div class="flex flex-wrap justify-between xl:mb-[50px] mb-[25px]">
        <div class="text-[#484848] text-xl 2xl:text-3xl lg:mb-2 mb-0 opacity-50 !font-bold">{{ getComponentTitle }}</div>
        <router-link :to="getComponentLink" class="text-main-blue xl:text-[1.3em] lg:text-[1em]">{{ getSelengkapnya }}</router-link>
      </div>
      <div class="lg:bg-white lg:rounded-2xl overflow-hidden">
        <div class="al-container flex xl:flex-row flex-col h-max">
          <div class="al-hl-img-cont xl:w-[38%] lg:w-full relative z-20">
            <single-image :addsClass="'h-full'" :imgClass="'lg:rounded-l-2xl lg:rounded-r-none rounded-2xl object-cover lg:h-full h-[400px] w-full'" :imgSrc="getHlImg"/>
          </div>
          <div class=" lg:pb-0 relative al-content-cont xl:w-[62%] text-left flex flex-col justify-between 2xl:pl-20 lg:pl-10">
            <div class="pr-[2rem] al-content 2xl:mt-20 xl:my-10 lg:mt-5 mt-8" >
              <div v-motion
              :initial = "{opacity: 0, scale: 0.9}"
              :visible = "{opacity: 1,scale: 1, transition: {
                  duration: 500,
                  type: 'keyframes',
                  ease: 'easeIn',
                },}"  v-if="getHlTitle" class="text-[#484848] text-subTitle al-text font-bold text-main-grey 2xl:text-[2.5em] xl:text-[2em] lg:text-[2em] leading-[1.2em] text-[1.4em]">{{ getHlTitle }}</div>
              <div v-motion
              :initial = "{opacity: 0, x: -200}"
              :visible = "{opacity: 1, x:0, transition: {
                  duration: 550,
                  type: 'keyframes',
                  delay: 400,
                  ease: 'easeIn',
                },}"  v-if="getHlExcerpt" class="text-[#484848] al-excerpt lg:text-xl text-lg my-8 2xl:mr-[30%] xl:mr-[15%] lg:mr-[5%]">{{ getHlExcerpt }}<router-link class="text-blue-700" :to="getHlLink">{{ getReadMore }}</router-link></div>
            </div>
            <swiper v-motion
              :initial = "{opacity: 0, y: 40}"
              :visible = "{opacity: 1, y:0, transition: {
                  duration: 550,
                  type: 'keyframes',
                  delay: 380,
                  ease: 'easeIneaseInOut',
                },}"  ref="alSwiper" class="al-slider relative w-[90%] mb-10 overflow-hidden left-0 lg:-left-[1rem]" :modules="modules" :freeMode="true" :slides-per-view="'auto'" :spaceBetween="16" :breakpoints="breakpoints" @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide class="al-img-cont rounded-2xl h-[200px] lg:!h-[200px]" v-for="(item, i) in getAlData" :key="i">
                <single-image  :addsClass="'h-full'" :imgClass="'al-img rounded-2xl h-full object-cover'" :imgSrc="getImgPath(item.image)" :imgLink="getSlideLink(item.slug)"/>
                <div class="al-shade rounded-2xl absolute h-full w-full top-0 left-0 bg-gradient-to-t from-black/80 to-black/20 lg:to-50% to-90% pointer-events-none">
                  <div class="al-title text-white absolute bottom-0 m-4 lg:text-base text-[0.8em] text-paraf">{{ getSlideTitle(item) }}</div>
                </div>
              </swiper-slide>
            </swiper>
              <div v-if="navBtnIsShowed && getAlDataTotal" v-bind:class="`${getAlDataTotal.total_item > 4 ? 'flex justify-center gap-10 lg:gap-0 lg:justify-between items-center relative bottom-0 lg:bottom-[9rem] right-0 lg:right-[1rem] z-10' : 'flex justify-center gap-10 lg:gap-0 lg:justify-between items-center relative bottom-0 lg:bottom-[9rem] right-0 lg:right-[1rem] z-10  md:hidden'}`" >
                  <button @click="swiperPrevSlide" class="rounded-full w-7 h-7 flex items-center justify-center bg-black"><font-awesome-icon class="text-white" :icon="['fa', 'chevron-left']" /></button>
                  <button @click="swiperNextSlide" class="rounded-full w-7 h-7 flex items-center justify-center bg-black"><font-awesome-icon class="text-white" :icon="['fa', 'chevron-right']" /></button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleImage from '@/components/SingleImage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/free-mode'

library.add(faChevronLeft, faChevronRight)

export default {
  name: 'ArticleList',
  components: {
    SingleImage,
    FontAwesomeIcon,
    Swiper,
    SwiperSlide
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      hlpost: {},
      posts: {},
      swiper: null
      /* breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 16
        }
      } */
    }
  },
  computed: {
    getImgPath () {
      return imgPath => {
        return process.env.VUE_APP_CDN_PATH + imgPath
      }
    },
    getHlImg () {
      let img
      if (this.$store.getters.currentAlData.highlight) {
        img = this.$store.getters.currentAlData.highlight.image
      } else {
        img = ''
      }
      return process.env.VUE_APP_CDN_PATH + img
    },
    getSlideLink () {
      return imgLink => {
        const lang = this.$store.getters.currentLang
        return '/' + lang + '/posts/' + imgLink
      }
    },
    getHlLink () {
      let link
      const lang = this.$store.getters.currentLang
      if (this.$store.getters.currentAlData.highlight) {
        link = this.$store.getters.currentAlData.highlight.slug
      } else {
        link = '#'
      }

      return '/' + lang + '/posts/' + link
    },
    getHlTitle () {
      let title
      const lang = this.$store.getters.currentLang

      if (this.$store.getters.currentAlData.highlight) {
        if (lang === 'en') {
          title = this.$store.getters.currentAlData.highlight.title_en
        } else if (lang === 'ch') {
          title = this.$store.getters.currentAlData.highlight.title_ch
        } else {
          title = this.$store.getters.currentAlData.highlight.title
        }

        if (title === '' || !title) {
          title = this.$store.getters.currentAlData.highlight.title
        }
      } else {
        title = ''
      }
      return title
    },
    getHlExcerpt () {
      let excerpt
      const lang = this.$store.getters.currentLang

      if (this.$store.getters.currentAlData.highlight) {
        if (lang === 'en') {
          excerpt = this.$store.getters.currentAlData.highlight.content_en
        } else if (lang === 'ch') {
          excerpt = this.$store.getters.currentAlData.highlight.content_ch
        } else {
          excerpt = this.$store.getters.currentAlData.highlight.content
        }

        if (excerpt === '' || !excerpt) {
          excerpt = this.$store.getters.currentAlData.highlight.content
        }

        excerpt = excerpt.replace(/<\/?[^>]+(>|$)/gi, '')
        excerpt = excerpt.substring(0, 200)
        excerpt += '...  '
      } else {
        excerpt = ''
      }
      return excerpt
    },
    getAlDataTotal () {
      return this.$store.getters.currentAlData.pagination
    },
    getAlData () {
      return this.$store.getters.currentAlData.posts
    },
    titleIsShowed () {
      const currOptions = this.data.al_show_options
      return currOptions.includes('show-title')
    },
    excerptIsShowed () {
      const currOptions = this.data.al_show_options
      return currOptions.includes('show-excerpt')
    },
    imageIsShowed () {
      const currOptions = this.data.al_show_options
      return currOptions.includes('show-image')
    },
    navBtnIsShowed () {
      const currOptions = this.data.al_show_options
      return currOptions.includes('show-nav-btn')
    },
    getComponentTitle () {
      let title
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        title = this.data.component_title_en
      } else if (lang === 'ch') {
        title = this.data.component_title_ch
      } else {
        title = this.data.component_title
      }

      if (title === '' || !title) {
        title = this.data.component_title
      }
      return title
    },
    getComponentLink () {
      let link
      const lang = this.$store.getters.currentLang
      if (this.data.component_link) {
        link = this.data.component_link
      } else {
        link = '/#'
      }

      return '/' + lang + link
    },
    getSlideTitle () {
      return item => {
        let title
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          title = item.title_en
        } else if (lang === 'ch') {
          title = item.title_ch
        } else {
          title = item.title
        }

        if (title === '' || !title) {
          title = item.title
        }
        return title
      }
    },
    getSelengkapnya () {
      let content
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        content = 'More'
      } else if (lang === 'ch') {
        content = ''
      } else {
        content = 'Selengkapnya'
      }

      if (content === '' || !content) {
        content = 'Selengkapnya'
      }
      return content
    },
    getReadMore () {
      let content
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        content = '(Read More)'
      } else if (lang === 'ch') {
        content = ''
      } else {
        content = '(Baca Selengkapnya)'
      }

      if (content === '' || !content) {
        content = '(Baca Selengkapnya)'
      }
      return content
    }
  },
  methods: {
    swiperPrevSlide () {
      this.$refs.alSwiper.$el.swiper.slidePrev()
    },
    swiperNextSlide () {
      this.$refs.alSwiper.$el.swiper.slideNext()
    }
  },
  setup () {
    const onSwiper = (swiper) => {
      // console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [FreeMode]
    }
  },
  mounted () {
    const postFilter = {
      count: this.data.show_count,
      category: this.data.al_cat_filter,
      options: this.data.al_show_options
    }

    localStorage.setItem('post_filter', JSON.stringify(postFilter))

    this.$store.dispatch('getAlData')
  }
}
</script>
