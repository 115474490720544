<template>
    <div class="component-container relative">
      <hero-slider :data="componentData" v-if="componentData.component_type === 'hero-slider'"/>
      <featured-content :data="componentData" v-if="componentData.component_type === 'featured-content'"/>
      <featured-slide :data="componentData" v-if="componentData.component_type === 'featured-slide'"/>
      <article-list :data="componentData" v-if="componentData.component_type === 'article-list'"/>
      <curator-gallery :data="componentData" v-if="componentData.component_type === 'curator-gallery'"/>
      <simple-page :data="componentData" v-if="componentData.component_type === 'simple-page'"/>
      <contact-form :data="componentData" v-if="componentData.component_type === 'contact-form'"/>
      <community-list :data="componentData" v-if="componentData.component_type === 'community-list'"/>
      <business-list :data="componentData" v-if="componentData.component_type === 'business-list'"/>
      <committee-slider :data="componentData" v-if="componentData.component_type === 'committee-slider'"/>
    </div>
</template>

<script>
import HeroSlider from '@/components/HeroSlider'
import FeaturedContent from '@/components/FeaturedContent'
import FeaturedSlide from '@/components/FeaturedSlide'
import ArticleList from '@/components/ArticleList'
import CuratorGallery from '@/components/CuratorGallery'
import SimplePage from '@/components/SimplePage'
import ContactForm from '@/components/ContactForm'
import CommunityList from '@/components/CommunityList'
import BusinessList from '@/components/BusinessList'
import CommitteeSlider from '@/components/CommitteeSlider'

export default {
  name: 'PageComponent',
  components: {
    HeroSlider,
    FeaturedContent,
    ArticleList,
    CuratorGallery,
    SimplePage,
    FeaturedSlide,
    ContactForm,
    CommunityList,
    BusinessList,
    CommitteeSlider
  },
  props: {
    componentData: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    // console.log(this.componentData)
  }
}
</script>
