<template>
  <div class="community-list my-10">
    <div class="mb-10 md:mb-16">
      <h1 class="text-subTitle text-2xl md:text-6xl font-bold">{{ getComponentTitle }}</h1>
      <div class="text-lg md:text-2xl my-4">{{ getComponentSubtext }}</div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 md:gap-10 lg:grid-cols-4">
      <router-link :to="getLangPath + 'posts/' + item.slug" class="hover:opacity-80 hover:shadow-lg transition-all card bg-[#E5E5E5] overflow-hidden rounded-xl" v-for="item in getClData" :key="item.id">
        <div class="card-header">
          <div class="card-image relative h-48 md:h-28 lg:h-48">
            <single-image :imgSrc="getImgPath(item.image)" :imgLink="getLangPath + 'posts/' + item.slug" :imgClass="'object-cover absolute top-0 left-0 h-full w-full'" alt="" />
          </div>
          <div class="card-body text-left p-3 md:p-6">
              <h5 v-text="getPostTitle(item)" class="mb-2 font-bold text-ellipsis line-clamp-1"></h5>
              <p class="text-sm font-bold text-ellipsis line-clamp-4" v-text="getPostExcerpt(item)"></p>
          </div>
        </div>
      </router-link>
    </div>
    <list-pagination :totalData="totalData" :perPage="this.data.show_count" :listType="'post'"/>
  </div>
</template>

<script>
import SingleImage from '@/components/SingleImage'
import ListPagination from '@/components/ListPagination'

export default {
  name: 'CommunityList',
  components: {
    SingleImage,
    ListPagination
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getImgPath () {
      return imgPath => {
        return process.env.VUE_APP_CDN_PATH + imgPath
      }
    },
    getClData () {
      return this.$store.getters.currentClData.posts
    },
    getPostExcerpt () {
      return item => {
        let excerpt
        const lang = this.$store.getters.currentLang

        if (item.content) {
          if (lang === 'en') {
            excerpt = item.content_en
          } else if (lang === 'ch') {
            excerpt = item.content_ch
          } else {
            excerpt = item.content
          }

          if (excerpt === '' || !excerpt) {
            excerpt = item.content
          }

          excerpt = excerpt.replace(/<\/?[^>]+(>|$)/gi, '')
          excerpt = excerpt.substring(0, 200)
          excerpt += '...  '
        } else {
          excerpt = ''
        }
        return excerpt
      }
    },
    totalData () {
      let totalData
      if (this.$store.getters.currentClData.pagination) {
        totalData = this.$store.getters.currentClData.pagination.total_item
      } else {
        totalData = 0
      }
      return totalData
    },
    getComponentTitle () {
      let title
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        title = this.data.component_title_en
      } else if (lang === 'ch') {
        title = this.data.component_title_ch
      } else {
        title = this.data.component_title
      }

      if (title === '' || !title) {
        title = this.data.component_title
      }
      return title
    },
    getComponentSubtext () {
      let subtext
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        subtext = this.data.component_subtext_en
      } else if (lang === 'ch') {
        subtext = this.data.component_subtext_ch
      } else {
        subtext = this.data.component_subtext
      }

      if (subtext === '' || !subtext) {
        subtext = this.data.component_subtext
      }
      return subtext
    },
    getLangPath () {
      const lang = this.$store.getters.currentLang
      return '/' + lang + '/'
    },
    getPostTitle () {
      return item => {
        let title
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          title = item.title_en
        } else if (lang === 'ch') {
          title = item.title_ch
        } else {
          title = item.title
        }

        if (title === '' || !title) {
          title = item.title
        }
        return title
      }
    }
  },
  data () {
    return {
      dummylists: [
        { id: 1, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 2, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 2 ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 3, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 3', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 4, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 4', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 1, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 2, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 2 ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 3, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 3', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 4, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 4', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 1, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 2, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 2 ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 3, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 3', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 4, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 4', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 1, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 2, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 2 ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 3, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 3', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 4, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 4', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 1, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 2, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 2 ', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 3, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 3', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' },
        { id: 4, thumbnail: 'https://psmti.or.id/uploads/2023/06/1-2.jpeg', title: 'Community - 4', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsam molestias ipsum minus alias culpa quia perferendis? Neque, debitis iste. Cupiditate voluptatum earum possimus quo, rerum nulla incidunt. Quasi, itaque!' }
      ]
    }
  },
  mounted () {
    const listFilter = {
      search: '',
      count: this.data.show_count,
      page: this.page
    }

    localStorage.setItem('list_filter', JSON.stringify(listFilter))

    this.$store.dispatch('getClData')
  }
}
</script>
