import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import HistoryOfView from '../views/HistoryOfView.vue'
import VisionMissionView from '../views/VisionMissionView.vue'
import CommitteeView from '../views/CommitteeView.vue'
import BusinessView from '../views/BusinessView.vue'
import CommunityView from '../views/CommunityView.vue'
import GalleryView from '../views/GalleryView.vue'
import PostsView from '../views/PostsView.vue'
import NotFoundView from '../views/NotFoundView.vue'

import store from '@/store'
import axios from 'axios'

const routes = [
  {
    path: '/:lang(\\id|en|ch)?/',
    name: 'home',
    meta: {
      api: 'pages/home'
    },
    component: HomeView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/kontak-kami',
    name: 'kontak-kami',
    meta: {
      api: 'pages/kontak-kami'
    },
    component: ContactUsView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/sejarah',
    name: 'sejarah',
    meta: {
      api: 'pages/sejarah'
    },
    component: HistoryOfView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/visi-misi',
    name: 'visi-misi',
    meta: {
      api: 'pages/visi-misi'
    },
    component: VisionMissionView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/pengurus',
    name: 'pengurus',
    meta: {
      api: 'pages/pengurus'
    },
    component: CommitteeView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/bisnis',
    name: 'bisnis',
    meta: {
      api: 'pages/bisnis'
    },
    component: BusinessView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/komunitas',
    name: 'komunitas',
    meta: {
      api: 'pages/komunitas'
    },
    component: CommunityView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/berita-it',
    name: 'it',
    meta: {
      api: 'pages/berita-it'
    },
    component: CommunityView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/berita-properti',
    name: 'properti',
    meta: {
      api: 'pages/berita-properti'
    },
    component: CommunityView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/berita-paguyuban',
    name: 'paguyuban',
    meta: {
      api: 'pages/berita-paguyuban'
    },
    component: CommunityView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/galeri',
    name: 'galeri',
    meta: {
      api: 'pages/galeri'
    },
    component: GalleryView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/tentang-kami',
    redirect: (route) => {
      if (route.params.lang) {
        return '/' + route.params.lang + '/sejarah'
      } else {
        return '/sejarah'
      }
    }
  },
  {
    path: '/:lang(\\id|en|ch)?/posts/:postslug',
    name: 'post',
    component: PostsView,
    props: true
  },
  {
    path: '/:lang(\\id|en|ch)?/404',
    name: 'not-found',
    meta: {
      api: 'pages/not-found'
    },
    component: NotFoundView,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: (route) => {
      if (route.params.lang) {
        return '/' + route.params.lang + '/404'
      } else {
        return '/404'
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_ROUTE_PATH),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      top: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('SET_ROUTE', to)
  if (to.params.lang) {
    store.commit('SET_LANG', to.params.lang)
  } else {
    store.commit('SET_LANG', 'id')
  }
  if (to.params.postslug) {
    store.commit('SET_API_ROUTE', 'posts/' + to.params.postslug)
    // console.log(process.env.VUE_APP_API_PATH + 'posts/' + to.params.postslug)
    axios.get(process.env.VUE_APP_API_PATH + 'posts/' + to.params.postslug).then(res => {
      if (res.data.pages.title === undefined && res.data.pages.title === undefined) {
        router.push({ name: 'not-found' })
      }
      setPostMetaData(res.data.pages, to.params.lang, res.data.general)
      store.commit('GET_PAGE_DATA', res.data)
    })
  } else if (to.meta.api) {
    store.commit('SET_API_ROUTE', to.meta.api)
    // console.log(process.env.VUE_APP_API_PATH + to.meta.api)
    axios.get(process.env.VUE_APP_API_PATH + to.meta.api).then(res => {
      setPageMetaData(res.data.pages, to.params.lang, res.data.general)
      store.commit('GET_PAGE_DATA', res.data)
    })
  } else {
    store.commit('SET_API_ROUTE', '')
    axios.get(process.env.VUE_APP_API_PATH).then(res => {
      setPageMetaData(res.data.pages, to.params.lang, res.data.general)
      store.commit('GET_PAGE_DATA', res.data)
    })
  }
  next()
})

function setPageMetaData (data, lang, gen) {
  if (lang === '') lang = 'id'

  let title = ''
  if (lang === 'en') {
    title = data.title_en
    if (data.meta_title_en) {
      title = data.meta_title_en
    }
  } else if (lang === 'ch') {
    title = data.title_ch
    if (data.meta_title_ch) {
      title = data.meta_title_ch
    }
  } else {
    title = data.title
    if (data.meta_title) {
      title = data.meta_title
    }
  }
  if (title === '' || !title) {
    title = data.meta_title
    if (title === '' || !title) {
      title = data.title
    }
  }
  if (title === undefined) {
    title = 'Page Not Found | Kuala Tungkal'
  }

  let desc = ''
  if (lang === 'en') {
    desc = data.description_en
    if (data.meta_description_en) {
      desc = data.meta_description_en
    }
  } else if (lang === 'ch') {
    desc = data.description_ch
    if (data.meta_description_ch) {
      desc = data.meta_description_ch
    }
  } else {
    desc = data.description
    if (data.meta_description) {
      desc = data.meta_description
    }
  }
  if (desc === '' || !desc) {
    desc = data.meta_description
    if (desc === '' || !desc) {
      desc = data.description
    }
  }
  if (desc === undefined) {
    desc = 'This Page is not available'
  }

  let imgData
  gen.forEach((item) => {
    if (item.slug === 'main-logo') imgData = item.content
  })

  document.documentElement.setAttribute('lang', lang)
  document.title = title

  if (document.querySelector('[name=description]')) {
    const rm = document.querySelector('[name=description]')
    rm.parentElement.removeChild(rm)
  }
  const descTag = document.createElement('meta')
  descTag.setAttribute('name', 'description')
  descTag.setAttribute('content', desc)
  document.head.appendChild(descTag)

  if (document.querySelector('[rel=canonical]')) {
    const rm = document.querySelector('[rel=canonical]')
    rm.parentElement.removeChild(rm)
  }
  const canonTag = document.createElement('link')
  canonTag.setAttribute('rel', 'canonical')
  canonTag.setAttribute('href', window.location.href)
  document.head.appendChild(canonTag)

  if (document.querySelector('[property="og:title"]')) {
    const rm = document.querySelector('[property="og:title"]')
    rm.parentElement.removeChild(rm)
  }
  const ogTItleTag = document.createElement('meta')
  ogTItleTag.setAttribute('property', 'og:title')
  ogTItleTag.setAttribute('content', title)
  document.head.appendChild(ogTItleTag)

  if (document.querySelector('[property="og:description"]')) {
    const rm = document.querySelector('[property="og:description"]')
    rm.parentElement.removeChild(rm)
  }
  const ogDescTag = document.createElement('meta')
  ogDescTag.setAttribute('property', 'og:description')
  ogDescTag.setAttribute('content', desc)
  document.head.appendChild(ogDescTag)

  if (document.querySelector('[property="og:url"]')) {
    const rm = document.querySelector('[property="og:url"]')
    rm.parentElement.removeChild(rm)
  }
  const ogLinkTag = document.createElement('meta')
  ogLinkTag.setAttribute('property', 'og:url')
  ogLinkTag.setAttribute('content', window.location.href)
  document.head.appendChild(ogLinkTag)

  if (document.querySelector('[property="og:type"]')) {
    const rm = document.querySelector('[property="og:type"]')
    rm.parentElement.removeChild(rm)
  }
  const ogTypeTag = document.createElement('meta')
  ogTypeTag.setAttribute('property', 'og:type')
  ogTypeTag.setAttribute('content', 'website')
  document.head.appendChild(ogTypeTag)

  if (document.querySelector('[property="og:image"]')) {
    const rm = document.querySelector('[property="og:image"]')
    rm.parentElement.removeChild(rm)
  }
  const ogImgTag = document.createElement('meta')
  ogImgTag.setAttribute('property', 'og:image')
  ogImgTag.setAttribute('content', imgData)
  document.head.appendChild(ogImgTag)
}

function setPostMetaData (data, lang, gen) {
  if (lang === '') lang = 'id'

  let title = ''
  if (lang === 'en') {
    title = data.title_en
    if (data.meta_title_en) {
      title = data.meta_title_en
    }
  } else if (lang === 'ch') {
    title = data.title_ch
    if (data.meta_title_ch) {
      title = data.meta_title_ch
    }
  } else {
    title = data.title + ' | Kuala Tungkal'
    if (data.meta_title) {
      title = data.meta_title
    }
  }
  if (title === '' || !title) {
    title = data.meta_title
    if (title === '' || !title) {
      title = data.title + ' | Kuala Tungkal'
    }
  }

  let desc = ''
  if (lang === 'en') {
    desc = data.description_en
    if (data.meta_description_en) {
      desc = data.meta_description_en
    }
  } else if (lang === 'ch') {
    desc = data.description_ch
    if (data.meta_description_ch) {
      desc = data.meta_description_ch
    }
  } else {
    desc = data.description
    if (data.meta_description) {
      desc = data.meta_description
    }
  }
  if (desc === '' || !desc) {
    desc = data.meta_description
    if (desc === '' || !desc) {
      desc = data.content
      desc = desc.replace(/<\/?[^>]+(>|$)/gi, '')
      desc = desc.substring(0, 97)
      desc += '...'
    }
  }

  let imgData
  gen.forEach((item) => {
    if (item.slug === 'main-logo') imgData = item.content
  })

  document.documentElement.setAttribute('lang', lang)
  document.title = title

  if (document.querySelector('[name=description]')) {
    const rm = document.querySelector('[name=description]')
    rm.parentElement.removeChild(rm)
  }
  const descTag = document.createElement('meta')
  descTag.setAttribute('name', 'description')
  descTag.setAttribute('content', desc)
  document.head.appendChild(descTag)

  if (document.querySelector('[rel=canonical]')) {
    const rm = document.querySelector('[rel=canonical]')
    rm.parentElement.removeChild(rm)
  }
  const canonTag = document.createElement('link')
  canonTag.setAttribute('rel', 'canonical')
  canonTag.setAttribute('href', window.location.href)
  document.head.appendChild(canonTag)

  if (document.querySelector('[property="og:title"]')) {
    const rm = document.querySelector('[property="og:title"]')
    rm.parentElement.removeChild(rm)
  }
  const ogTItleTag = document.createElement('meta')
  ogTItleTag.setAttribute('property', 'og:title')
  ogTItleTag.setAttribute('content', title)
  document.head.appendChild(ogTItleTag)

  if (document.querySelector('[property="og:description"]')) {
    const rm = document.querySelector('[property="og:description"]')
    rm.parentElement.removeChild(rm)
  }
  const ogDescTag = document.createElement('meta')
  ogDescTag.setAttribute('property', 'og:description')
  ogDescTag.setAttribute('content', desc)
  document.head.appendChild(ogDescTag)

  if (document.querySelector('[property="og:url"]')) {
    const rm = document.querySelector('[property="og:url"]')
    rm.parentElement.removeChild(rm)
  }
  const ogLinkTag = document.createElement('meta')
  ogLinkTag.setAttribute('property', 'og:url')
  ogLinkTag.setAttribute('content', window.location.href)
  document.head.appendChild(ogLinkTag)

  if (document.querySelector('[property="og:type"]')) {
    const rm = document.querySelector('[property="og:type"]')
    rm.parentElement.removeChild(rm)
  }
  const ogTypeTag = document.createElement('meta')
  ogTypeTag.setAttribute('property', 'og:type')
  ogTypeTag.setAttribute('content', 'website')
  document.head.appendChild(ogTypeTag)

  if (document.querySelector('[property="og:image"]')) {
    const rm = document.querySelector('[property="og:image"]')
    rm.parentElement.removeChild(rm)
  }
  const ogImgTag = document.createElement('meta')
  ogImgTag.setAttribute('property', 'og:image')
  ogImgTag.setAttribute('content', imgData)
  document.head.appendChild(ogImgTag)
}

export default router
