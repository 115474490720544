<template>
  <ul class="pagination">
    <li :class="getPrevClass" @click="handlePrevPage">&lt;</li>
    <li v-for="index in totalPage" :key="index" :class="getLiClass(index)" @click="handleNumberJump(index)">{{ index }}</li>
    <li :class="getNextClass" @click="handleNextPage" >&gt;</li>
  </ul>
</template>

<script>
export default {
  name: 'ListPagination',
  props: {
    totalData: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 12
    },
    listType: {
      type: String,
      default: 'post'
    }
  },
  computed: {
    totalPage () {
      const totalPage = Math.ceil(this.totalData / this.perPage)
      return totalPage
    },
    getLiClass () {
      return index => {
        if (index === this.page) {
          return 'active'
        } else {
          return ''
        }
      }
    },
    getPrevClass () {
      if (this.page === 1) {
        return 'disabled'
      } else {
        return ''
      }
    },
    getNextClass () {
      const totalPage = Math.ceil(this.totalData / this.perPage)
      if (this.page === totalPage) {
        return 'disabled'
      } else {
        return ''
      }
    }
  },
  methods: {
    handlePrevPage () {
      this.page--

      const listFilter = {
        search: '',
        count: this.perPage,
        page: this.page
      }
      localStorage.setItem('list_filter', JSON.stringify(listFilter))
      if (this.listType === 'business') {
        this.$store.dispatch('getBlData')
      } else if (this.listType === 'post') {
        this.$store.dispatch('getClData')
      }
    },
    handleNextPage () {
      this.page++

      const listFilter = {
        search: '',
        count: this.perPage,
        page: this.page
      }
      localStorage.setItem('list_filter', JSON.stringify(listFilter))
      if (this.listType === 'business') {
        this.$store.dispatch('getBlData')
      } else if (this.listType === 'post') {
        this.$store.dispatch('getClData')
      }
    },
    handleNumberJump (page) {
      this.page = page

      const listFilter = {
        search: '',
        count: this.perPage,
        page: this.page
      }
      localStorage.setItem('list_filter', JSON.stringify(listFilter))
      if (this.listType === 'business') {
        this.$store.dispatch('getBlData')
      } else if (this.listType === 'post') {
        this.$store.dispatch('getClData')
      }
    }
  },
  data () {
    return {
      page: 1
    }
  }
}
</script>
