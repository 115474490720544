<template>
    <div class="single-image" :class="imgAddsClass">
        <router-link class="h-full block w-full" :to="getImgLink">
          <picture>
            <source :srcset="getImgSrcMobile" media="(max-width: 600px)"/>
            <source :srcset="getImgSrc" media="(max-width: 2000px)"/>
            <img :class="getImgClass" :src="getImgSrc" :alt="getImgAlt"/>
          </picture>
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'SingleImage',
  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    addsClass: {
      type: String,
      default: ''
    },
    imgLink: {
      type: String,
      default: '#'
    },
    imgAlt: {
      type: String,
      default: ''
    },
    imgClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    getImgSrc () {
      return this.imgSrc
    },
    getImgSrcMobile () {
      const imgSrc = this.imgSrc
      const arrImgSrc = imgSrc.split('.')
      arrImgSrc[arrImgSrc.length - 2] = arrImgSrc[arrImgSrc.length - 2] + ''
      const imgSrcMobile = arrImgSrc.join('.')
      return imgSrcMobile
    },
    imgAddsClass () {
      return this.addsClass
    },
    getImgLink () {
      return this.imgLink
    },
    getImgAlt () {
      return this.imgAlt
    },
    getImgClass () {
      return this.imgClass
    }
  }
}
</script>
