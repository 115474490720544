<template>
  <div class="post-container">
    <hero-image v-if="getHeroImage" class="featured-image" :inArticle="true" :heroSrc="getHeroImage" :heroText="getHeroText" :heroDesc="getHeroDesc"/>
    <insert-article v-if="articleClean" v-html="articleClean"/>
    <div v-else class="min-h-screen relative bg-neutral-300">
      <div class="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">{{ getNotFoundText }}</div>
    </div>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage'
import InsertArticle from '@/components/InsertArticle'

export default {
  name: 'PostsView',
  components: {
    HeroImage,
    InsertArticle
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    component: {
      type: Object,
      default: () => {}
    },
    order: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    articleClean () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))

      let articleText = ''
      const lang = this.$store.getters.currentLang

      if (contentArray !== undefined) {
        if (lang === 'en') {
          articleText = this.content.content_en
        } else if (lang === 'ch') {
          articleText = this.content.content_ch
        } else {
          articleText = this.content.content
        }
        if (articleText === '' || !articleText) {
          articleText = this.content.content
        }
      }

      if (articleText) {
        articleText = articleText.replace('<p><img src="', '<p class="article-img"><img src="' + process.env.VUE_APP_CDN_BASE)
      }
      return articleText
    },
    getHeroImage () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroImage = ''
      if (contentArray !== undefined) {
        if (contentArray.image !== '') {
          heroImage = process.env.VUE_APP_CDN_PATH + contentArray.image
        }
        if (contentArray.image === undefined) {
          heroImage = null
        }
      }
      return heroImage
    },
    getHeroText () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroText = ''
      const lang = this.$store.getters.currentLang

      if (contentArray !== undefined) {
        if (lang === 'en') {
          heroText = contentArray.title_en
        } else if (lang === 'ch') {
          heroText = contentArray.title_ch
        } else {
          heroText = contentArray.title
        }
        if (heroText === '' || !heroText) {
          heroText = contentArray.title
        }
      }
      return heroText
    },
    getHeroDesc () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroDesc = ''
      if (contentArray !== undefined) {
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          heroDesc = contentArray.subtitle_en
        } else if (lang === 'ch') {
          heroDesc = contentArray.subtitle_ch
        } else {
          heroDesc = contentArray.subtitle
        }
        if (heroDesc === '' || !heroDesc) {
          heroDesc = contentArray.subtitle
        }
      }
      return heroDesc
    },
    getNotFoundText () {
      let text
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        text = 'Current Page is Unavailable'
      } else if (lang === 'ch') {
        text = '当前页面不可用'
      } else {
        text = 'Halaman Ini Tidak Tersedia'
      }

      return text
    }
  },
  mounted () {
    // console.log(this.content)
  }
}
</script>
