<template>
  <div class="featured-slide">
      <swiper ref="fcSwiper" :modules="modules" effect="fade" :loop="true" :slides-per-view="1" :pagination="paginationOption" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide class="relative flex flex-wrap flex-row 2xl:my-[10%] xl:my-[15%] lg:my-[20%]" v-for="(item, i) in getFcData" :key="i">
          <div v-if="imageIsShowed" class="fc-image xl:w-7/12 lg:w-5/12">
            <single-image :imgSrc="getSlideImgPath(item.image)" :addsClass="''" :imgClass="'rounded-2xl'"/>
          </div>
          <div class="fc-content xl:w-5/12 lg:w-7/12">
            <div class="text-left">
              <div class="2xl:mx-20 2xl:my-8 xl:mr-10 xl:ml-16 xl:my-4 lg:mr-5 lg:ml-8 lg:my-2">
                <div class="text-[#484848] opacity-40">{{ data.component_title }}</div>
                <a class="text-[#0025FB]" :href="getSlideLink(item.slug)">Selengkapnya</a>
              </div>
              <span class="line-separator"></span>
              <div class="2xl:mx-20 2xl:my-8 xl:mr-10 xl:ml-16 xl:my-4 lg:mr-5 lg:ml-8 lg:my-2">
                <div v-if="titleIsShowed" class="xl:text-[3em] lg:text-[2em] leading-[1.2em]">{{ item.title }}</div>
                <div v-if="excerptIsShowed" class="my-6">{{ getSlideExcerpt(item.content) }}<a class="text-blue-700" :href="getSlideLink(item.slug)">(Read More)</a></div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div v-if="navBtnIsShowed" class="custom-button flex flex-wrap items-center rounded-lg bg-neutral-200 w-[215px]">
          <button @click="swiperPrevSlide" class="btn-left text-center text-[40px] w-[49%] py-[8px]"><font-awesome-icon :icon="['fa','chevron-left']" /></button>
          <span class="bg-black w-[1%] h-[45px]"></span>
          <button @click="swiperNextSlide" class="btn-right text-center text-[40px] w-[49%] py-[8px]"><font-awesome-icon :icon="['fa','chevron-right']" /></button>
        </div>
      </swiper>

  </div>
</template>

<script>
import SingleImage from '@/components/SingleImage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Pagination, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
// import 'swiper/css/navigation'

library.add(faChevronLeft, faChevronRight)

export default {
  name: 'FeaturedSlide',
  components: {
    SingleImage,
    FontAwesomeIcon,
    Swiper,
    SwiperSlide
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      posts: {},
      paginationOption: {
        clickable: true
      },
      swiper: null
    }
  },
  computed: {
    getAllSlide () {
      return this.data.slider_items
    },
    getSlideImgPath () {
      return imgPath => {
        return process.env.VUE_APP_CDN_PATH + imgPath
      }
    },
    getSlideLink () {
      return slideLink => {
        return '/posts/' + slideLink
      }
    },
    getFcData () {
      return this.$store.getters.currentFsData.posts
    },
    getSlideExcerpt () {
      return postRaw => {
        let excerpt = postRaw.replace(/<\/?[^>]+(>|$)/gi, '')
        excerpt = excerpt.substring(0, 200)
        return excerpt + '...  '
      }
    },
    titleIsShowed () {
      const currOptions = this.data.fs_show_options
      return currOptions.includes('show-title')
    },
    excerptIsShowed () {
      const currOptions = this.data.fs_show_options
      return currOptions.includes('show-excerpt')
    },
    imageIsShowed () {
      const currOptions = this.data.fs_show_options
      return currOptions.includes('show-image')
    },
    navBtnIsShowed () {
      const currOptions = this.data.fs_show_options
      return currOptions.includes('show-nav-btn')
    }
  },
  methods: {
    swiperPrevSlide () {
      this.$refs.fcSwiper.$el.swiper.slidePrev()
    },
    swiperNextSlide () {
      this.$refs.fcSwiper.$el.swiper.slideNext()
    }
  },
  setup () {
    const onSwiper = (swiper) => {
      // console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, EffectFade]
    }
  },
  mounted () {
    const postFilter = {
      count: this.data.show_count,
      category: this.data.fs_cat_filter,
      options: this.data.fs_show_options
    }
    localStorage.setItem('post_filter', JSON.stringify(postFilter))

    this.$store.dispatch('getFsData')
  }
}
</script>
