<template>
  <div id="notification" class="notification fixed top-[20%] left-1/2 border-2 rounded-md py-2 px-4 -translate-x-2/4 bg-white duration-300 pointer-events-none block" :class="getNotificationStyle">{{ getNotificationMessage }}</div>
</template>

<script>
export default {
  name: 'Notification',
  computed: {
    getNotificationStyle () {
      const notification = this.$store.getters.currentNotification
      if (notification !== undefined) {
        if (notification.status === 'success') {
          return 'border-main-blue opacity-100'
        } else if (notification.status === 'error') {
          return 'border-rose-500 opacity-100'
        } else {
          return 'opacity-0'
        }
      } else {
        return 'opacity-0'
      }
    },
    getNotificationMessage () {
      const notification = this.$store.getters.currentNotification
      let msg = ''
      if (notification !== undefined) {
        msg = notification.message
      } else {
        msg = ''
      }

      return msg
    }
  }
}
</script>
