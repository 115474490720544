<template>
  <article class="text-justify c-container mx-auto my-10">
  </article>
</template>

<script>
export default {
  name: 'InsertArticle'
}
</script>
