<template>
  <div v-motion
  :initial = "{opacity: 0}"
  :visible = "{opacity: 1,  transition: {
      duration: 700,
      type: 'keyframes',
      ease: 'easeIn',
    },}"
  class="featured-content target">
    <div class="relative flex flex-wrap flex-row my-24 items-center">
        <div v-if="getImgPath" class="fc-image xl:w-[53%] lg:w-5/12">
          <single-image :imgSrc="getImgPath" :addsClass="''" :imgClass="'rounded-2xl'"/>
        </div>
        <div class="fc-content xl:w-[47%] lg:w-7/12">
          <div class="text-left">
            <div class="2xl:mx-20 2xl:my-4 xl:mr-10 xl:ml-16 xl:my-4 lg:mr-5 lg:ml-8 lg:my-2 mt-5 mb-2">
              <template v-if="data.component_title">
                <div class="text-main-grey text-subTitle text-[2em] leading-[1.2em] mt-10 font-semibold">{{ getComponentTitle }}</div>
                <div v-if="data.component_subtext" class="text-[1.2rem] font-light text-main-grey 2xl:text-2xl mt-2 leading-8 line-clamp-7">{{ getComponentSubText }}</div>
                <router-link v-if="data.component_link" class="text-main-blue text-lg 2xl:text-2xl " :to="getLangPath + data.component_link">{{ getSelengkapnya }}</router-link>
              </template>
              <template v-else>
                <div v-if="data.fc_title" class="text-subTitle text-main-grey xl:text-5xl text-[2em] leading-[1.2em]">{{ getFcTitle }}</div>
              </template>
            </div>
            <span class="line-separator"></span>
            <div class="2xl:mx-20 2xl:my-16 xl:mr-10 xl:ml-16 xl:my-4 lg:mr-5 lg:ml-8 lg:my-2 my-6">
              <div v-if="data.component_title && data.fc_title" class="text-main-grey text-subTitle text-[2em] leading-[1.2em] mt-10 font-semibold">{{ getFcTitle }}</div>
                <div v-if="data.fc_content" class="featured-text text-[1rem] font-light text-main-grey 2xl:text-xl my-6 leading-8"><insert-article v-html="getFcContent"/><router-link v-if="data.fc_link" class="text-blue-700 text-[1.2em]" :to="getLangPath + data.fc_link">{{ getReadMore }}</router-link></div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import SingleImage from '@/components/SingleImage'
import InsertArticle from '@/components/InsertArticle'

export default {
  name: 'FeaturedContent',
  components: {
    SingleImage,
    InsertArticle
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      swiper: null
    }
  },
  computed: {
    getImgPath () {
      const imgPath = this.data.fc_image
      return process.env.VUE_APP_CDN_PATH + imgPath
    },
    titleIsShowed () {
      const currOptions = this.data.fc_show_options
      return currOptions ? currOptions.includes('show-title') : false
    },
    excerptIsShowed () {
      const currOptions = this.data.fc_show_options
      return currOptions ? currOptions.includes('show-excerpt') : false
    },
    imageIsShowed () {
      const currOptions = this.data.fc_show_options
      return currOptions ? currOptions.includes('show-image') : false
    },
    navBtnIsShowed () {
      const currOptions = this.data.fc_show_options
      return currOptions ? currOptions.includes('show-nav-btn') : false
    },
    getComponentTitle () {
      let title
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        title = this.data.component_title_en
      } else if (lang === 'ch') {
        title = this.data.component_title_ch
      } else {
        title = this.data.component_title
      }

      if (title === '' || !title) {
        title = this.data.component_title
      }
      return title
    },
    getComponentSubText () {
      let subtext
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        subtext = this.data.component_subtext_en
      } else if (lang === 'ch') {
        subtext = this.data.component_subtext_ch
      } else {
        subtext = this.data.component_subtext
      }

      if (subtext === '' || !subtext) {
        subtext = this.data.component_subtext
      }
      return subtext
    },
    getFcTitle () {
      let title
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        title = this.data.fc_title_en
      } else if (lang === 'ch') {
        title = this.data.fc_title_ch
      } else {
        title = this.data.fc_title
      }

      if (title === '' || !title) {
        title = this.data.fc_title
      }
      return title
    },
    getFcContent () {
      let content
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        content = this.data.fc_content_en
      } else if (lang === 'ch') {
        content = this.data.fc_content_ch
      } else {
        content = this.data.fc_content
      }

      if (content === '' || !content) {
        content = this.data.fc_content
      }
      return content
    },
    getSelengkapnya () {
      let content
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        content = 'More'
      } else if (lang === 'ch') {
        content = ''
      } else {
        content = '(Selengkapnya)'
      }

      if (content === '' || !content) {
        content = '(Selengkapnya)'
      }
      return content
    },
    getReadMore () {
      let content
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        content = '(Read More)'
      } else if (lang === 'ch') {
        content = ''
      } else {
        content = '(Selengkapnya)'
      }

      if (content === '' || !content) {
        content = '(Selengkapnya)'
      }
      return content
    },
    getLangPath () {
      const lang = this.$store.getters.currentLang
      return '/' + lang
    }
  },
  mounted () {
    // console.log(this.data)
  }
}
</script>
