<template>
    <div class="committee-slider my-10 ">
        <h1 v-if="this.data.component_title" class="text-3xl  text-paraf 3xl:text-6xl">{{ this.data.component_title }}</h1>
        <div class="wrap-committe flex">
           <div v-for="(item, i) in getAllSlide" :key="i" class=" flex-column justify-content-center">
                <div class="w-[11rem] h-[15rem] mx-auto 3xl:w-[20rem] rounded-b-xl relative flex items-end justify-center">
                  <img class="rounded-b-xl absolute bottom-0 left-0 object-cover" :src="getImgPath(item.slider_image)">
                </div>
                <div class="flex flex-col gap-2 w-[80%] mx-auto mt-5">
                    <h1 class="text-2xl 3xl:text-5xl text-[#3F3F3F] text-paraf font-medium">{{ item.slider_name }}</h1>
                    <hr>
                    <p class="text-[#c23b22] font-medium text-paraf 3xl:text-4xl">{{ item.slider_title }}</p>
                </div>
         </div>
        </div>
        <!-- mobile -->
          <swiper class="cmtSwiper wrap-committe-mobile" :modules="modules" :navigation="true" :slides-per-view="5" :spaceBetween="25" :breakpoints="{
            '1440': {slidesPerView: 5},
            '1280': {slidesPerView: 4},
            '1024': {slidesPerView: 3},
            '768': {slidesPerView: 4},
            '425' : {slidesPerView: 2},
            '375' : {slidesPerView: 2},
            '320' : {slidesPerView: 1}
            }">
            <swiper-slide v-for="(item, i) in getAllSlide" :key="i" class="flex flex-col gap-10">
                <div class="w-[15rem] h-[20rem] mx-auto 3xl:w-[20rem] rounded-b-xl relative flex items-end justify-center">
                  <img class="rounded-b-xl absolute bottom-0 left-0 object-cover" :src="getImgPath(item.slider_image)">
                </div>
                <div class="flex flex-col gap-2 w-[80%] mx-auto">
                    <h1 class="text-md 3xl:text-5xl text-[#3F3F3F] font-1 font-medium">{{ item.slider_name }}</h1>
                    <hr>
                    <p class="text-[#c23b22] font-medium font-1 3xl:text-4xl">{{ item.slider_title }}</p>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css'

export default {
  name: 'CommitteeSlider',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getImgPath () {
      return imgPath => {
        return process.env.VUE_APP_CDN_PATH + imgPath
      }
    },
    getAllSlide () {
      const slideItems = []
      const slideLength = this.data.cmt_slider_items.length
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        for (let i = 0; i < slideLength; i++) {
          const item = {
            slider_name: this.data.cmt_slider_items_en[i].slider_name,
            slider_title: this.data.cmt_slider_items_en[i].slider_title,
            slider_image: this.data.cmt_slider_items[i].slider_image
          }
          slideItems.push(item)
        }
      } else if (lang === 'ch') {
        for (let i = 0; i < slideLength; i++) {
          const item = {
            slider_name: this.data.cmt_slider_items_ch[i].slider_name,
            slider_title: this.data.cmt_slider_items_ch[i].slider_title,
            slider_image: this.data.cmt_slider_items[i].slider_image
          }
          slideItems.push(item)
        }
      } else {
        for (let i = 0; i < slideLength; i++) {
          const item = {
            slider_name: this.data.cmt_slider_items[i].slider_name,
            slider_title: this.data.cmt_slider_items[i].slider_title,
            slider_image: this.data.cmt_slider_items[i].slider_image
          }
          slideItems.push(item)
        }
      }

      for (let i = 0; i < slideLength; i++) {
        if (slideItems[i].slider_name === '' || !slideItems[i].slider_name) {
          slideItems[i].slider_name = this.data.cmt_slider_items[i].slider_name
        }
        if (slideItems[i].slider_title === '' || !slideItems[i].slider_title) {
          slideItems[i].slider_title = this.data.cmt_slider_items[i].slider_title
        }
      }

      return slideItems
    }
  },
  setup () {
    return {
      modules: [Navigation]
    }
  },
  mounted () {
    // console.log(this.data)
  }
}
</script>
