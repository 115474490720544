<template>
  <div class="business-list my-10">
    <div class="mb-10 md:mb-16">
      <h1 class="text-2xl md:text-6xl font-bold text-subTitle ">{{ getComponentTitle }}</h1>
      <div class="text-lg md:text-2xl my-4">{{ getComponentSubtext }}</div>
    </div>
    <div class="mb-10 lg:w-[80%] mx-auto md:mb-16 form-search relative flex items-center">
      <input v-model="keyword" @input="searchItem(keyword)" type="text" class="w-full border-2 shadow-lg rounded-full p-5" :placeholder="getDataContent('form-search-placeholder')">
      <span class="absolute right-5"><font-awesome-icon class="text-xl  lg:text-xl"  :icon="['fa', 'search']" /></span>
    </div>
    <div class="grid grid-cols-3 sm:grid-cols-3 gap-5 md:gap-10 lg:grid-cols-6  md:grid-cols-3">
      <div class="hover:opacity-80 hover:shadow-lg transition-all card bg-[#E5E5E5] overflow-hidden rounded-xl cursor-pointer" v-for="item in getBlData" :key="item.id" @click="openModal(item)">
        <div class="card-header">
          <div class="card-image relative h-48 md:h-28 lg:h-48 bg-white">
            <img :src="getImgPath(item.image)" class="object-contain h-full w-9/12 m-auto" alt="">
          </div>
          <div class="card-body text-left p-2 md:p-2 ">
            <h5 v-text="getBusinessName(item)" class="text-center text-sm font-bold text-ellipsis"></h5>
          </div>
        </div>
      </div>
    </div>
    <!-- <list-pagination :totalData="totalData" :perPage="this.data.show_count" :listType="'business'"/> -->
    <div class=" business-modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/50" :class="isModalOpen">
      <div class="relative w-auto my-6 mx-auto max-w-3xl">
        <div class="border-0 sm:rounded-t-lg md:rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <span class="close-modal absolute text-main-blue text-2xl right-2 top-2 cursor-pointer" @click="closeModal">&#10006;</span>
          <h3 class="text-[22px] pt-[50px] lg:text-left text-center sm:px-4 md:pl-[70px]">{{ getBusinessName(this.currBusiness) }}</h3>
          <a target="_blank" :href="this.currBusiness.link">
            <div class="relative p-6 lg:flex lg:align-start lg:flex-row-reverse flex-col-reverse align-center">
              <div class="sm:w-full md:w-[60%] md:px-[50px] sm:text-center">
                <p class="my-4 text-slate-700 sm:text-[18px] md:text-[18px] leading-relaxed">{{ getBusinessContent(this.currBusiness) }}</p>
                <a class="btn-visit" v-if="currBusiness.link" target="_blank" :href="this.currBusiness.link">Selengkapnya</a>
              </div>
              <div class="flex justify-center items-center sm:w-full md:w-[40%]">
                <img :src="getImgPath(this.currBusiness.image)" :alt="this.currBusiness.name" width="250" height="auto" class="md:w-full">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
// import ListPagination from '@/components/ListPagination'

library.add(faSearch)

export default {
  name: 'BusinessList',
  components: {
    FontAwesomeIcon
    // ListPagination
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getBlData () {
      return this.$store.getters.currentBlData.business
    },
    getImgPath () {
      return imgPath => {
        return process.env.VUE_APP_CDN_PATH + imgPath
      }
    },
    isModalOpen () {
      let modalClass
      if (this.modalOpen) {
        modalClass = 'fixed'
      } else {
        modalClass = 'hidden'
      }
      return modalClass
    },
    totalData () {
      let totalData
      if (this.$store.getters.currentBlData.pagination) {
        totalData = this.$store.getters.currentBlData.pagination.total_item
      } else {
        totalData = 0
      }
      return totalData
    },
    getComponentTitle () {
      let title
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        title = this.data.component_title_en
      } else if (lang === 'ch') {
        title = this.data.component_title_ch
      } else {
        title = this.data.component_title
      }

      if (title === '' || !title) {
        title = this.data.component_title
      }
      return title
    },
    getComponentSubtext () {
      let subtext
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        subtext = this.data.component_subtext_en
      } else if (lang === 'ch') {
        subtext = this.data.component_subtext_ch
      } else {
        subtext = this.data.component_subtext
      }

      if (subtext === '' || !subtext) {
        subtext = this.data.component_subtext
      }
      return subtext
    },
    getDataContent () {
      return key => {
        const generalData = this.$store.getters.currentPageData.general
        let generalArray = []
        let data = {}
        if (generalData !== undefined) generalArray = JSON.parse(JSON.stringify(generalData))
        generalArray.forEach((item) => {
          if (item.slug === key) data = item
        })

        let content
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          content = data.content_en
        } else if (lang === 'ch') {
          content = data.content_ch
        } else {
          content = data.content
        }

        if (content === '' || !content) {
          content = data.content
        }

        return content
      }
    },
    getBusinessName () {
      return item => {
        let name
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          name = item.name_en
        } else if (lang === 'ch') {
          name = item.name_ch
        } else {
          name = item.name
        }

        if (name === '' || !name) {
          name = item.name
        }
        return name
      }
    },
    getBusinessContent () {
      return item => {
        let content
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          content = item.content_en
        } else if (lang === 'ch') {
          content = item.content_ch
        } else {
          content = item.content
        }

        if (content === '' || !content) {
          content = item.content
        }
        return content
      }
    }
  },
  methods: {
    openModal (item) {
      this.currBusiness = item
      this.modalOpen = true
    },
    closeModal (item) {
      this.currBusiness = {}
      this.modalOpen = false
    },
    searchItem (keyword) {
      const listFilter = {
        search: keyword,
        count: this.data.show_count,
        page: this.page
      }

      localStorage.setItem('list_filter', JSON.stringify(listFilter))

      this.$store.dispatch('getBlData')
    }
  },
  data () {
    return {
      lists: [
        { id: 1, thumbnail: 'https://psmti.or.id/uploads/2023/01/OSMTI-JATIM-scaled.jpg', title: 'Community ' },
        { id: 2, thumbnail: 'https://psmti.or.id/uploads/2023/01/OSMTI-JATIM-scaled.jpg', title: 'Community - 2 ' },
        { id: 3, thumbnail: 'https://psmti.or.id/uploads/2023/01/OSMTI-JATIM-scaled.jpg', title: 'Community - 3' },
        { id: 4, thumbnail: 'https://psmti.or.id/uploads/2023/01/OSMTI-JATIM-scaled.jpg', title: 'Community - 4' }
      ],
      currBusiness: {},
      modalOpen: false
    }
  },
  mounted () {
    const listFilter = {
      search: '',
      count: this.data.show_count,
      page: this.page
    }

    localStorage.setItem('list_filter', JSON.stringify(listFilter))

    this.$store.dispatch('getBlData')
  }
}
</script>
