<template>
    <footer class="footer flex items-center">
      <div class="c-container mx-auto flex lg:flex-row flex-col flex-wrap justify-between items-center  h-auto">
        <div class="footer-left lg:self-start lg:pb-0 text-class">
          <single-image :imgSrc="getDataContent('footer-logo')" :imgLink="'/id'" :addsClass="getDataClass('footer-logo')" :imgClass="'w-full mx-auto px-10'" />
          <div class="mt-3 !mb-0 text-[21px]">
               <p class="pb-2"> PAGUYUBAN ARISAN </p>
               <p class="pb-2"> KUALA TUNGKAL INDONESIA </p>
               <p class="pb-2"> 印尼洞葛同鄉會</p>
          </div>
        </div>
        <div class="footer-mid self-start">
          <div class="2xl:mx-10 mb-10 lg:mx-0 lg:text-left text-center">
            <div class="footer-title !mb-0 text-[26px]">{{ this.getDataContent('title-kantor-utama') }}</div>
             <div class="flex footer-content font-bold !text-[1rem] my-4 ">
            <font-awesome-icon  class="text-[25px] pt-[5px]"  :icon="['fas', 'location-dot']" />
              <p class="ml-4 mt-2">  {{ this.getDataContent('content-kantor-utama') }}</p>
            </div>
          </div>
          <div class="2xl:mx-10 lg:mx-0 lg:text-left text-center">
            <div class="!mb-0 footer-title text-[26px]">{{ this.getDataContent('title-telepon-email') }}</div>
              <div class="footer-content  font-bold !text-[1rem] my-4">
              <div class="kontak-wrap flex items-center">
               <font-awesome-icon class="text-[25px]  " :icon="getIcon('logo-wa')" />
              <a class="ml-4" target="_blank" href="https://api.whatsapp.com/send/?phone=628129955321&text&type=phone_number&app_absent=0">
              {{ this.getDataContent('content-telepon') }}</a></div>
              <div class="kontak-wrap flex items-center">
                <font-awesome-icon class="text-[25px] pt-[8px] " :icon="getIcon('logo-email')" />
                <a class="ml-4" target="_blank" href="mailto:paguyuban.arisan.ktl@gmail.com" >
                {{ this.getDataContent('content-email') }}</a></div>
            </div>
          </div>
        </div>
        <div class="footer-right self-start">
          <div class="mx-auto">
            <div class="footer-title text-[26px] text-white">{{ this.getDataContent('title-media-social') }}</div>
            <div class="flex flex-wrap justify-center align-center content-start w-[250px] mx-auto my-4">
              <a :href="getDataContent('instagram-link')">
                <div class="rounded-full bg-white w-[45px] h-[45px] mx-3 my-4">
                  <font-awesome-icon class="text-[25px] pt-[8px]" :icon="getIcon('instagram-symbol')" />
                </div>
              </a>
              <a :href="getDataContent('youtube-link')">
                <div class="rounded-full bg-white w-[45px] h-[45px] mx-3 my-4">
                  <font-awesome-icon class="text-[25px] pt-[8px]" :icon="getIcon('youtube-symbol')" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
import SingleImage from '@/components/SingleImage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faMap } from '@fortawesome/free-regular-svg-icons'

library.add(faInstagram, faYoutube, faMap)

export default {
  name: 'SiteFooter',
  components: {
    SingleImage,
    FontAwesomeIcon
  },
  props: {
    general: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getDataClass () {
      return key => {
        const generalData = this.$store.getters.currentPageData.general
        let generalArray = []
        let data = {}
        if (generalData !== undefined) generalArray = JSON.parse(JSON.stringify(generalData))
        generalArray.forEach((item) => {
          if (item.slug === key) data = item
        })
        return data.slug
      }
    },
    getDataContent () {
      return key => {
        const generalData = this.$store.getters.currentPageData.general
        let generalArray = []
        let data = {}
        if (generalData !== undefined) generalArray = JSON.parse(JSON.stringify(generalData))
        generalArray.forEach((item) => {
          if (item.slug === key) data = item
        })

        let content
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          content = data.content_en
        } else if (lang === 'ch') {
          content = data.content_ch
        } else {
          content = data.content
        }

        if (content === '' || !content) {
          content = data.content
        }

        return content
      }
    },
    getIcon () {
      return key => {
        const generalData = this.$store.getters.currentPageData.general
        let generalArray = []
        let icon = ''
        if (generalData !== undefined) generalArray = JSON.parse(JSON.stringify(generalData))
        generalArray.forEach((item) => {
          if (item.slug === key) icon = item.content
        })

        const arrcon = icon.split(',')
        return arrcon
      }
    }
  }
}
</script>
