<template>
  <main class="community">
    <hero-image :heroSrc="getHeroImage" :heroText="getHeroText" :heroLink="getHeroLink" :heroBtnText="getHeroBtnText" :heroDesc="getHeroDesc"/>
    <template v-if="order[0] !== ''">
      <template v-for="(item, i) in order" :key="i">
        <section class="component-section overflow-x-hidden" :class="getSectionPosition(i)">
          <div class="mx-auto min-h-screen" :class="getComponentContainer(i)">
            <page-component :componentData="getComponentData(item)"/>
          </div>
        </section>
      </template>
    </template>
    <template v-else>
      <section class="component-section overflow-x-hidden">
        <div class="c-container mx-auto py-10">
          <h1>This is a community page</h1>
        </div>
      </section>
    </template>
  </main>
</template>

<script>
import PageComponent from '@/components/PageComponent'
import HeroImage from '@/components/HeroImage'

export default {
  name: 'CommunityView',
  components: {
    PageComponent,
    HeroImage
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    component: {
      type: Object,
      default: () => {}
    },
    order: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getSectionPosition () {
      return i => {
        let sectionClass = 'section-' + (i + 1)
        if (i + 1 % 2 === 0) sectionClass += ' bg-neutral-300'
        return sectionClass
      }
    },
    getComponentData () {
      return item => {
        const res = this.component
        let allComponent = []
        if (allComponent !== undefined) allComponent = JSON.parse(JSON.stringify(res))
        let currComponent = {}
        allComponent.forEach(element => {
          if (element.slug === item) currComponent = element
        })
        return currComponent
      }
    },
    getComponentContainer () {
      return i => {
        let compContainer = 'w-full'
        if (i + 1 !== 0) compContainer = 'c-container'
        return compContainer
      }
    },
    getHeroImage () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroImage = ''
      if (contentArray !== undefined) {
        if (contentArray.hero_image !== '') {
          heroImage = process.env.VUE_APP_CDN_PATH + contentArray.hero_image
        }
      }
      return heroImage
    },
    getHeroLink () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroLink = ''
      if (contentArray !== undefined) heroLink = contentArray.hero_btn_link
      return heroLink
    },
    getHeroText () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroText = ''
      if (contentArray !== undefined) {
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          heroText = contentArray.hero_text_en
        } else if (lang === 'ch') {
          heroText = contentArray.hero_text_ch
        } else {
          heroText = contentArray.hero_text
        }
        if (heroText === '' || !heroText) {
          heroText = contentArray.hero_text
        }
      }
      return heroText
    },
    getHeroBtnText () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroBtnText = ''
      if (contentArray !== undefined) {
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          heroBtnText = contentArray.hero_btn_text_en
        } else if (lang === 'ch') {
          heroBtnText = contentArray.hero_btn_text_ch
        } else {
          heroBtnText = contentArray.hero_btn_text
        }
        if (heroBtnText === '' || !heroBtnText) {
          heroBtnText = contentArray.hero_btn_text
        }
      }
      return heroBtnText
    },
    getHeroDesc () {
      const res = this.content
      let contentArray
      if (res !== undefined) contentArray = JSON.parse(JSON.stringify(res))
      let heroDesc = ''
      if (contentArray !== undefined) {
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          heroDesc = contentArray.description_en
        } else if (lang === 'ch') {
          heroDesc = contentArray.description_ch
        } else {
          heroDesc = contentArray.description
        }
        if (heroDesc === '' || !heroDesc) {
          heroDesc = contentArray.description
        }
      }
      return heroDesc
    }
  },
  unmounted () {
    this.props = {
      content: {
        type: Object,
        default: () => {}
      },
      component: {
        type: Object,
        default: () => {}
      },
      order: {
        type: Array,
        default: () => []
      }
    }
  }
}
</script>
