<template>
  <div v-motion
              :initial = "{opacity: 0}"
              :visible = "{opacity: 1,  transition: {
                  duration: 400,
                  type: 'keyframes',
                  ease: 'easeIn',
                },}"   class="curator-gallery">
    <div class="my-[5%]">
      <div class="text-[#484848] text-subTitle my-10 2xl:text-[3em] text-2xl font-semibold xl:text-[2.5em] lg:text-[2em] leading-[1.2em]">{{ getComponentTitle }}</div>
      <div class="gallery-slot">
        <insert-script :src="this.data.curator_script" v-html="insertScript(this.data.curator_tag)"/>
        <!--
        <a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import InsertScript from '@/components/InsertScript'

export default {
  name: 'CuratorGallery',
  components: {
    InsertScript
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    insertScript () {
      return item => {
        return item
      }
    },
    getComponentTitle () {
      let title
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        title = this.data.component_title_en
      } else if (lang === 'ch') {
        title = this.data.component_title_ch
      } else {
        title = this.data.component_title
      }

      if (title === '' || !title) {
        title = this.data.component_title
      }
      return title
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.$el.querySelector('.crt-grid-post') !== null) {
        const itemHeight = this.$el.querySelector('.crt-grid-post').clientHeight
        const curatorHeight = itemHeight * 2 + (itemHeight * 0.1)
        this.$el.querySelector('.crt-feed-window').style.height = curatorHeight + 'px'
      }
    }, 5000)
    // console.log(this.data)
  }
}
</script>
