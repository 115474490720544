<template>
  <div class="simple-page">
    <insert-article v-html="articleClean"/>
  </div>
</template>

<script>
import InsertArticle from '@/components/InsertArticle'

export default {
  name: 'SimplePage',
  components: {
    InsertArticle
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    articleClean () {
      let articleText
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        articleText = this.data.rich_content_en
      } else if (lang === 'ch') {
        articleText = this.data.rich_content_ch
      } else {
        articleText = this.data.rich_content
      }

      if (articleText) {
        articleText = articleText.replace('<p><img src="', '<p class="article-img"><img src="' + process.env.VUE_APP_CDN_BASE)
      }
      return articleText
    }
  },
  mounted () {
    // console.log(this.data)
  }
}
</script>
