<template>
  <div class="grid items-center my-16 lg:grid-cols-2">
      <div class="p-10 rounded-lg bg-[#F1F1F1]">
            <form class="lg:p-5 text-left">
                  <div v-if="showParts('name-input')" class="form-group mb-5">
                        <label for="name" class="block font-bold text-sm">{{ getDataContent('form-name-title') }}</label>
                        <input type="text" class="px-4 py-3 mt-3 w-full rounded-md form-control" :class="getNameBorder" v-model="formdata.name" @input="checkName()" :placeholder="getDataContent('form-name-placeholder')">
                        <span class="relative text-rose-500 top-[5px] text-[0.8em]" :class="getNameMessage" >{{ this.errmsg.name }}</span>
                  </div>
                  <div v-if="showParts('email-input')" class="form-group mb-5">
                        <label for="email" class="block font-bold text-sm">{{ getDataContent('form-email-title') }}</label>
                        <input type="email" class="px-4 py-3 mt-3 w-full rounded-md form-control" :class="getEmailBorder" v-model="formdata.email" @input="checkEmail(formdata.email)" :placeholder="getDataContent('form-email-placeholder')">
                        <span class="relative text-rose-500 top-[5px] text-[0.8em]" :class="getEmailMessage" >{{ this.errmsg.email }}</span>
                  </div>
                  <div v-if="showParts('subject-input')" class="form-group mb-5">
                        <label for="subject" class="block font-bold text-sm">{{ getDataContent('form-subject-title') }}</label>
                        <input type="text" class="px-4 py-3 mt-3 w-full rounded-md form-control" :class="getSubjectBorder" v-model="formdata.subject" @input="checkSubject()" :placeholder="getDataContent('form-subject-placeholder')">
                        <span class="relative text-rose-500 top-[5px] text-[0.8em]" :class="getSubjectMessage" >{{ this.errmsg.subject }}</span>
                  </div>
                  <div v-if="showParts('message-input')" class="form-group mb-5">
                        <label for="name" class="block font-bold text-sm">{{ getDataContent('form-message-title') }}</label>
                        <textarea class="h-[150px] px-4 py-3 mt-3 w-full rounded-md form-control" :class="getMessageBorder" v-model="formdata.message" @input="checkMessage()" :placeholder="getDataContent('form-message-placeholder')"></textarea>
                        <span class="relative text-rose-500 top-[5px] text-[0.8em]" :class="getMessageMessage" >{{ this.errmsg.message }}</span>

                  </div>
                  <div class="text-center">
                        <button @click="contactSubmit" class="shadow-lg rounded-2xl bg-blue-700 text-white xl:text-[2em] xl:py-[25px] xl:px-[80px] lg:text-[1.5em] py-[15px] px-[40px] text-[1.2em]" >{{ getDataContent('form-submit-button-text') }}</button>
                  </div>
            </form>
      </div>
      <div v-if="showParts('all-contact-detail')" class="container mt-10 lg:mt-0 p-5 text-left lg:pl-[4rem] lg:pr-[9rem]">
            <div v-if="showParts('office-detail')" class="grid mb-10 grid-cols-10 w-full">
                  <div class="col-span-2 lg:col-span-1 text-main-blue text-3xl lg:text-2xl text-center icon">
                        <font-awesome-icon :icon="['fa', 'map-marker-alt']" />
                  </div>
                  <div class="mt-2 lg:mt-0 content col-span-8 lg:col-span-9 lg:ml-[1rem]">
                        <h5 class="text-[#020202] text-2xl font-bold mb-5">{{ getDataContent('title-kantor-utama') }}</h5>
                        <p>{{ getDataContent('content-kantor-utama') }}</p>
                  </div>
            </div>
            <div v-if="showParts('phone-detail')" class="grid mb-10 grid-cols-10 w-full">
                  <div class="col-span-2 lg:col-span-1 text-main-blue text-3xl lg:text-2xl icon text-center">
                        <font-awesome-icon :icon="['fa', 'phone']" />
                  </div>
                  <div class="mt-2 lg:mt-0 content col-span-8 lg:col-span-9 lg:ml-[1rem]">
                        <h5 class="text-[#020202] text-2xl font-bold mb-5">{{ getDataContent('title-contact-form-phone') }}</h5>
                        <p>{{ getDataContent('content-telepon') }}</p>
                        <p>{{ getDataContent('content-telepon-2') }}</p>
                  </div>
            </div>
            <div v-if="showParts('email-detail')" class="grid mb-10 grid-cols-10 w-full">
                  <div class="col-span-2 lg:col-span-1 text-main-blue text-3xl lg:text-2xl icon text-center">
                        <font-awesome-icon :icon="['fa', 'envelope']" />
                  </div>
                  <div class="mt-2 lg:mt-0 content col-span-8 lg:col-span-9 lg:ml-[1rem]">
                        <h5 class="text-[#020202] text-2xl font-bold mb-5">{{ getDataContent('title-contact-form-email') }}</h5>
                        <p><a :href="'mailto:' + getDataContent('content-email')">{{ getDataContent('content-email') }}</a></p>
                  </div>
            </div>
            <div v-if="showParts('social-links')" class="grid mb-10 grid-cols-10 w-full">
                  <div class="col-span-1 text-main-blue text-2xl icon text-center">
                  </div>
                  <div class="content col-span-10 lg:col-span-9 lg:ml-[1rem]">
                        <h5 class="text-3xl text-center lg:text-left font-light mb-5 font-1 uppercase font-thin">{{ getDataContent('title-contact-form-social') }}</h5>
                        <div class="flex items-center justify-center lg:justify-start">
                              <a :href="getDataContent('twitter-link')" target="_blank" class="hover:opacity-80 transition-all w-16 h-16 lg:w-10 lg:h-10 text-white bg-black flex items-center justify-center rounded-full mr-3"><font-awesome-icon class="text-3xl lg:text-xl"  :icon="['fab', 'twitter']" /></a>
                              <a :href="getDataContent('facebook-link')" target="_blank" class="hover:opacity-80 transition-all w-16 h-16 lg:w-10 lg:h-10 text-white bg-black flex items-center justify-center rounded-full mr-3"><font-awesome-icon class="text-3xl lg:text-xl"  :icon="['fab', 'facebook']" /></a>
                              <a :href="getDataContent('instagram-link')" target="_blank" class="hover:opacity-80 transition-all w-16 h-16 lg:w-10 lg:h-10 text-white bg-black flex items-center justify-center rounded-full mr-3"><font-awesome-icon class="text-3xl lg:text-xl"  :icon="['fab', 'instagram']" /></a>
                              <a :href="getDataContent('youtube-link')" target="_blank" class="hover:opacity-80 transition-all w-16 h-16 lg:w-10 lg:h-10 text-white bg-black flex items-center justify-center rounded-full mr-3"><font-awesome-icon class="text-3xl lg:text-xl"  :icon="['fab', 'youtube']" /></a>
                        </div>
                  </div>
            </div>
      </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add(faMapMarkerAlt, faPhone, faEnvelope, faFacebook, faTwitter, faYoutube, faInstagram)

export default {
  name: 'ContactForm',
  components: {
    FontAwesomeIcon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getDataContent () {
      return key => {
        const generalData = this.$store.getters.currentPageData.general
        let generalArray = []
        let data = {}
        if (generalData !== undefined) generalArray = JSON.parse(JSON.stringify(generalData))
        generalArray.forEach((item) => {
          if (item.slug === key) data = item
        })

        let content
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          content = data.content_en
        } else if (lang === 'ch') {
          content = data.content_ch
        } else {
          content = data.content
        }

        if (content === '' || !content) {
          content = data.content
        }

        return content
      }
    },
    showParts () {
      return parts => {
        const currOptions = this.data.cf_show_options
        return currOptions.includes(parts)
      }
    },
    getNameBorder () {
      if (this.errmsg.name !== '') {
        return 'border-rose-500 border-solid border-2'
      }
      return ''
    },
    getEmailBorder () {
      if (this.errmsg.email !== '') {
        return 'border-rose-500 border-solid border-2'
      }
      return ''
    },
    getSubjectBorder () {
      if (this.errmsg.subject !== '') {
        return 'border-rose-500 border-solid border-2'
      }
      return ''
    },
    getMessageBorder () {
      if (this.errmsg.message !== '') {
        return 'border-rose-500 border-solid border-2'
      }
      return ''
    },
    getNameMessage () {
      if (this.errmsg.name !== '') {
        return 'block'
      }
      return 'hidden'
    },
    getEmailMessage () {
      if (this.errmsg.email !== '') {
        return 'block'
      }
      return 'hidden'
    },
    getSubjectMessage () {
      if (this.errmsg.subject !== '') {
        return 'block'
      }
      return 'hidden'
    },
    getMessageMessage () {
      if (this.errmsg.message !== '') {
        return 'block'
      }
      return 'hidden'
    }
  },
  methods: {
    contactSubmit (e) {
      e.preventDefault()
      if (this.formdata.name === '') {
        this.errmsg.name = 'Nama wajib diisi'
      }
      if (this.formdata.email === '') {
        this.errmsg.email = 'Email wajib diisi'
      }
      if (this.formdata.subject === '') {
        this.errmsg.subject = 'Subjek wajib diisi'
      }
      if (this.formdata.message === '') {
        this.errmsg.message = 'Pesan wajib diisi'
      }

      const isFilled = Object.values(this.errmsg).every(x => x === '')
      if (isFilled) {
        localStorage.setItem('contact_data', JSON.stringify(this.formdata))
        this.$store.dispatch('sendContactMail')
      }
    },
    checkEmail (email) {
      const emailPattern = /^[^@]+@[^@]+\.[^@]+$/
      if (emailPattern.test(email) || email === '') {
        this.errmsg.email = ''
      } else {
        this.errmsg.email = 'Email tidak valid'
      }
    },
    checkName () {
      this.errmsg.name = ''
    },
    checkSubject () {
      this.errmsg.subject = ''
    },
    checkMessage () {
      this.errmsg.message = ''
    }
  },
  data () {
    return {
      formdata: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      errmsg: {
        name: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  },
  mounted () {
    // console.log(this.data)
  }
}
</script>
