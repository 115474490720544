<template>
  <div class="404-container">
    <div class="min-h-screen relative bg-neutral-300">
      <div class="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">{{ getNotFoundText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    component: {
      type: Object,
      default: () => {}
    },
    order: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getNotFoundText () {
      let text
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        text = 'Current Page is Unavailable'
      } else if (lang === 'ch') {
        text = '当前页面不可用'
      } else {
        text = 'Halaman Ini Tidak Tersedia'
      }

      return text
    }
  }
}
</script>
