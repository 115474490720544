<template>
  <div class="hero-slider">
    <swiper :modules="modules" :slides-per-view="1" :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide class="relative" v-for="(item, i) in getAllSlide" :key="i">
          <picture>
            <source :srcset="getSlideImgPath(item.slider_image)" media="(max-width: 600px)"/>
            <source :srcset="getSlideImgPath(item.slider_image)" media="(max-width: 2000px)"/>
            <img class="w-full h-screen object-cover" :src="getSlideImgPath(item.slider_image)"/>
          </picture>
          <div class="absolute z-10 lg:top-0 bottom-0 left-0 lg:bg-gradient-to-r bg-gradient-to-t from-neutral-800 to-transparent lg:w-1/2 w-full lg:h-full h-3/4"></div>
          <div class="absolute z-20 lg:top-[55%] lg:bottom-[10%] bottom-[25%] xl:translate-y-[-50%] lg:translate-y-[-25%] xl:left-[12%] lg:left-[7%] lg:w-1/3 w-full lg:text-left text-center">
            <div v-if="item.slider_text" class="lg:text-[2.2em] text-[2em] xl:leading-[1em] leading-[1em] text-white lg:mx-0 mx-10 lg:mb-10 3xl:mx-10 mb-5 ">
            <p>{{ item.slider_text }}</p>
            </div>
            <a v-if="item.slider_btn_text" class="rounded-2xl mt-5 bg-[#C73B0A] block w-max mx-auto lg:mx-0 shadow-xl lg:mr-auto text-white xl:text-[.9em] lg:text-[1.5em] 3xl:mx-10 py-[10px] px-[40px] text-[1.2em]" target="_blank" :href="getSlideLink(item.slider_url)">{{ item.slider_btn_text }}</a>
          </div>
        </swiper-slide>
      </swiper>
  </div>
</template>

<script>
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'

export default {
  name: 'HeroSlider',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getAllSlide () {
      const slideItems = []
      const slideLength = this.data.slider_items.length
      const lang = this.$store.getters.currentLang

      if (lang === 'en') {
        for (let i = 0; i < slideLength; i++) {
          const item = {
            slider_text: this.data.slider_items_en[i].slider_text,
            slider_btn_text: this.data.slider_items_en[i].slider_btn_text,
            slider_image: this.data.slider_items[i].slider_image,
            slider_url: this.data.slider_items[i].slider_url
          }
          slideItems.push(item)
        }
      } else if (lang === 'ch') {
        for (let i = 0; i < slideLength; i++) {
          const item = {
            slider_text: this.data.slider_items_ch[i].slider_text,
            slider_btn_text: this.data.slider_items_ch[i].slider_btn_text,
            slider_image: this.data.slider_items[i].slider_image,
            slider_url: this.data.slider_items[i].slider_url
          }
          slideItems.push(item)
        }
      } else {
        for (let i = 0; i < slideLength; i++) {
          const item = {
            slider_text: this.data.slider_items[i].slider_text,
            slider_btn_text: this.data.slider_items[i].slider_btn_text,
            slider_image: this.data.slider_items[i].slider_image,
            slider_url: this.data.slider_items[i].slider_url
          }
          slideItems.push(item)
        }
      }

      for (let i = 0; i < slideLength; i++) {
        if (slideItems[i].slider_text === '' || !slideItems[i].slider_text) {
          slideItems[i].slider_text = this.data.slider_items[i].slider_text
        }
        if (slideItems[i].slider_btn_text === '' || !slideItems[i].slider_btn_text) {
          slideItems[i].slider_btn_text = this.data.slider_items[i].slider_btn_text
        }
      }

      return slideItems
    },
    getSlideImgPath () {
      return imgPath => {
        return process.env.VUE_APP_CDN_PATH + imgPath
      }
    },
    getSlideImgPathMobile () {
      return imgPath => {
        const arrPath = imgPath.split('.')
        arrPath[0] = arrPath[0] + '-m'
        const mobileImgPath = arrPath.join('.')
        return process.env.VUE_APP_CDN_PATH + mobileImgPath
      }
    },
    getSlideLink () {
      return slideLink => {
        return slideLink
      }
    }
  },
  setup () {
    const onSwiper = (swiper) => {
      // console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay]
    }
  },
  mounted () {
    // console.log(this.data)
  }
}
</script>
