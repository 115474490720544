<template>
  <div>
    <nav class="navbar !h-max desktop-nav fixed z-50" :class="getNavSize">
        <div class="c-container mx-auto flex flex-row flex-wrap justify-between items-end content-start lg:items-center xl:items-end xl:static ">
            <div class="navbar-left lg:w-3/12 2xl:w-4/12 xl:mb-3">
                <single-image :imgSrc="mainLogoImg" :imgLink="getLangPath" :addsClass="mainLogoClass" />
            </div>
            <div class="navbar-right flex flex-row justify-end items-center lg:w-9/12 2xl:w-8/12 gap-[1rem] 3xl:gap-0">
              <template v-for="(navitem, i) in navigation" :key="i">
                <template v-if="!navitem.has_child && !navitem.parent_id">
                  <a class="nav-item hover:text-main-#c23b22" :href="navitem.external_link" target="_blank" v-if="navitem.external_link"><font-awesome-icon :icon="getLinkIcon(navitem.link_icon)" v-if="navitem.link_icon"/> {{ getTitle(navitem) }}</a>
                  <router-link class="nav-item hover:text-main-#c23b22" :to="getLangPath + navitem.slug" v-else><font-awesome-icon :icon="getLinkIcon(navitem.link_icon)" v-if="navitem.link_icon"/> {{ getTitle(navitem) }}</router-link>
                </template>
                <template v-if="navitem.has_child && !navitem.parent_id">
                  <div class="nav-dropdown group 3xl:mr-0">
                      <a class="nav-dropdown-title nav-item hover:text-main-#c23b22" :to="getLangPath + navitem.slug" target="_blank" v-if="navitem.external_link"><font-awesome-icon :icon="getLinkIcon(navitem.link_icon)" v-if="navitem.link_icon"/> {{ getTitle(navitem) }}<font-awesome-icon class="chevron-down ml-2" :icon="['fa', 'chevron-down']" /></a>
                      <router-link class="nav-dropdown-title nav-item hover:text-main-#c23b22 " :to="getLangPath + navitem.slug" v-else><font-awesome-icon :icon="getLinkIcon(navitem.link_icon)" v-if="navitem.link_icon"/> {{ getTitle(navitem) }}<font-awesome-icon class="chevron-down ml-2" :icon="['fa', 'chevron-down']" /></router-link>
                      <div class="nav-dropdown-content  hidden group-hover:flex absolute flex-col flex-wrap items-start justify-start duration-300 rounded-b-2xl shadow-md">
                        <div class="nav-dropdown-items py-5">
                          <template v-for="(childitem, j) in navitem.submenu" :key="j">
                            <a class="nav-item w-full hover:text-main-#c23b22" :href="childitem.external_link" v-if="childitem.external_link" target="_blank"><font-awesome-icon :icon="getLinkIcon(childitem.link_icon)" v-if="childitem.link_icon"/> {{ childitem.title }}</a>
                            <router-link class=" nav-item hover:text-main-#c23b22" :to="getLangPath + childitem.slug" v-else><font-awesome-icon :icon="getLinkIcon(childitem.link_icon)" v-if="childitem.link_icon"/> {{ getTitle(childitem) }}</router-link>
                          </template>
                        </div>
                      </div>
                  </div>
                </template>
              </template>
              <div class="lang-switch justify-end ml-4 hidden">
                <div class="flex flex-wrap flex-row justify-between items-center border-2 border-solid border-[#0022f8] rounded-lg w-[120px]">
                  <router-link class="btn-id text-center text-[15px] hover:bg-[#0022f8] hover:text-white" :to="'/id' + getCurrentPath" :class="getLangClass('id')">ID</router-link>
                  <template v-if="isNotLang('id') && isNotLang('en')">
                    <span class="bg-[#0022f8] w-[1px] h-[25px]"></span>
                  </template>
                  <router-link class="btn-en text-center text-[15px] hover:bg-[#0022f8] hover:text-white" :to="'/en' + getCurrentPath" :class="getLangClass('en')">EN</router-link>
                  <template v-if="isNotLang('en') && isNotLang('ch')">
                    <span class="bg-[#0022f8] w-[1px] h-[25px]"></span>
                  </template>
                  <router-link class="btn-ch text-center text-[15px] hover:bg-[#0022f8] hover:text-white" :to="'/ch' + getCurrentPath" :class="getLangClass('ch')">CH</router-link>
                </div>
              </div>
          </div>
      </div>
    </nav>
    <nav class="navbar mobile-nav fixed z-50">
      <div class="c-container mx-8 flex flex-row flex-wrap justify-between items-center content-start h-full">
        <div class="navbar-left h-full grow-0 "  @click="toggleMenuMobile" >
            <single-image :imgSrc="mainLogoImg" :imgLink="getLangPath" :addsClass="mainLogoClass" />
        </div>
        <div class="navbar-right h-full grow-0">
          <div id="burger-icon" @click="toggleMenuMobile">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div id="mobile-nav-menu" class="nav-menu px-8 flex flex-col w-screen bg-gradient-to-t from-gray-300 to-white to-80% h-0 overflow-hidden text-right">
        <template v-for="(navitem, i) in navigation" :key="i">
          <template v-if="!navitem.has_child && !navitem.parent_id">
            <a @click="toggleMenuMobile" class="nav-item w-full hover:text-main-#c23b22 " :href="navitem.external_link" target="_blank" v-if="navitem.external_link">
            <font-awesome-icon :icon="getLinkIcon(navitem.link_icon)" v-if="navitem.link_icon"/> {{ navitem.title }}</a>
            <router-link @click="toggleMenuMobile"  class="nav-item w-full hover:text-main-#c23b22 " :to="getLangPath + navitem.slug" v-else>
            <font-awesome-icon :icon="getLinkIcon(navitem.link_icon)" v-if="navitem.link_icon"/> {{ navitem.title }}</router-link>
          </template>
          <template v-if="navitem.has_child && !navitem.parent_id">
            <div  class="nav-dropdown group">
                <router-link v-bind:id="navitem.id" class="nav-dropdown-title w-full nav-item hover:text-main-#c23b22" :to="'#'" @click="toggleChildMenu('toggle', $event)"><div class="nav-dropdown-btn inline p-5">
                <font-awesome-icon v-bind:id=" 'chevDown' + navitem.id" class="chevron-down" :icon="['fa','chevron-down']" />
                <font-awesome-icon v-bind:id=" 'chevUp' + navitem.id" class="chevron-up hidden" :icon="['fa','chevron-up']" /></div>{{ navitem.title }}</router-link>
                <div v-bind:id="navitem.slug"  class="nav-dropdown-content flex flex-col flex-wrap items-start justify-start">
                  <template  v-for="(childitem, j) in navitem.submenu" :key="j">
                    <a  class="nav-item w-full hover:text-main-#c23b22" :href="childitem.external_link" v-if="childitem.external_link"><font-awesome-icon :icon="getLinkIcon(childitem.link_icon)" v-if="childitem.link_icon"/> {{ childitem.title }}</a>
                    <router-link  @click="toggleMenuMobile" class="nav-item w-full hover:text-main-#c23b22" :to="getLangPath + childitem.slug" v-else><font-awesome-icon :icon="getLinkIcon(childitem.link_icon)" v-if="childitem.link_icon"/> {{ childitem.title }}</router-link>
                  </template>
                </div>
            </div>
          </template>
        </template>
        <div class="lang-switch hidden justify-center">
          <div class="flex flex-wrap flex-row justify-between items-center border-2 border-solid border-[#0022F8] rounded-lg w-[120px]">
            <router-link class="btn-id text-center text-[15px] hover:bg-[#0022F8] hover:text-white" :to="'/id/' + getCurrentPath" :class="getLangClass('id')">ID</router-link>
            <template v-if="isNotLang('id') && isNotLang('en')">
              <span class="bg-[#0022F8] w-[1px] h-[25px]"></span>
            </template>
            <router-link class="btn-en text-center text-[15px] hover:bg-[#0022F8] hover:text-white" :to="'/en/' + getCurrentPath" :class="getLangClass('en')">EN</router-link>
            <template v-if="isNotLang('en') && isNotLang('ch')">
              <span class="bg-[#0022F8] w-[1px] h-[25px]"></span>
            </template>
            <router-link class="btn-ch text-center text-[15px] hover:bg-[#0022F8] hover:text-white" :to="'/ch/' + getCurrentPath" :class="getLangClass('ch')">CH</router-link>
          </div>
        </div>
        <div class="nav-socmed"></div>
      </div>
    </nav>
  </div>
</template>

<script>
import SingleImage from '@/components/SingleImage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown, faChevronUp, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faTelegram, faLine, faFacebookF, faTwitter, faInstagram, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

library.add(faChevronDown, faChevronUp, faWhatsapp, faTelegram, faLine, faPhone, faFacebookF, faTwitter, faInstagram, faYoutube, faLinkedinIn)

export default {
  name: 'NavBar',
  components: {
    SingleImage,
    FontAwesomeIcon
  },
  props: {
    navigation: {
      type: Object,
      default: () => {}
    },
    mainLogo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    mainLogoImg () {
      return this.mainLogo.content
    },
    mainLogoClass () {
      return this.mainLogo.slug + ' w-max'
    },
    getNavSize () {
      return this.navScrollClass
    },
    getLangClass () {
      return lang => {
        return this.$store.getters.currentLang === lang ? 'bg-main-blue text-white' : 'bg-transparent text-main-blue'
      }
    },
    isNotLang () {
      return lang => {
        return this.$store.getters.currentLang !== lang
      }
    },
    getTitle () {
      return item => {
        let title
        const lang = this.$store.getters.currentLang

        if (lang === 'en') {
          title = item.title_en
        } else if (lang === 'ch') {
          title = item.title_ch
        } else {
          title = item.title
        }

        if (title === '' || !title) {
          title = item.title
        }
        return title
      }
    },
    getLangPath () {
      const lang = this.$store.getters.currentLang
      return '/' + lang + '/'
    },
    getCurrentPath () {
      let currPath = this.$route.path
      const arrPath = currPath.split('/')
      if (arrPath[1] === 'en' || arrPath[1] === 'ch' || arrPath[1] === 'id') {
        arrPath.splice(1, 1)
      }
      currPath = arrPath.join('/')
      return currPath
    },
    getLinkIcon () {
      return icon => {
        const arrcon = icon.split(',')
        return arrcon
      }
    }
  },
  data () {
    return {
      navScrollClass: ''
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      if (window.pageYOffset >= 60) {
        this.navScrollClass = 'nav-small'
        document.querySelector('.desktop-nav .nav-dropdown-content').style.top = '73px'
      } else {
        document.querySelector('.desktop-nav .nav-dropdown-content').style.top = '85px'
      }
      if (window.pageYOffset < 60) this.navScrollClass = ''
    },
    toggleMenuMobile () {
      const navToggle = document.getElementById('burger-icon')
      navToggle.classList.toggle('open')

      const navMenu = document.getElementById('mobile-nav-menu')
      navMenu.classList.toggle('open')
    },
    toggleChildMenu (msg, e) {
      const navMenu = document.getElementById('tentang-kami')
      const navMenuKomunitas = document.getElementById('komunitas')
      const toggle = document.getElementById(e.target.id)

      const chevDown = document.getElementById('chevDown4')
      const chevUp = document.getElementById('chevUp4')
      const chevDown2 = document.getElementById('chevDown8')
      const chevUp2 = document.getElementById('chevUp8')
      if (toggle.id === '4') {
        navMenu.classList.toggle('open')
        navMenuKomunitas.classList.remove('open')
        chevDown2.classList.add('hidden')
        chevUp2.classList.remove('hidden')
        if (chevDown.classList.contains('hidden')) {
          chevDown.classList.remove('hidden')
          chevUp.classList.add('hidden')
        } else {
          chevDown.classList.add('hidden')
          chevUp.classList.remove('hidden')
        }
      } else if (toggle.id === '8') {
        navMenuKomunitas.classList.toggle('open')
        navMenu.classList.remove('open')
        chevDown.classList.add('hidden')
        chevUp.classList.remove('hidden')
        if (chevDown2.classList.contains('hidden')) {
          chevDown2.classList.remove('hidden')
          chevUp2.classList.add('hidden')
        } else {
          chevDown2.classList.add('hidden')
          chevUp2.classList.remove('hidden')
        }
      }
    }
  }
}
</script>
