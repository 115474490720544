<template>
  <section v-if="!empty && heroSrc !== ''" class="component-section overflow-x-hidden section-1">
    <template v-if="heroSrc !== ''">
      <div class="hero-image w-full relative" :class="inArticle ? 'overflow-y-hidden h-[75vh]' : 'min-h-screen'">
        <single-image :addsClass="'pointer-events-none'" :imgSrc="heroSrc" :imgLink="'#'" :imgClass="'w-full object-cover absolute top-0 left-0 h-full'"/>
        <div class="absolute z-10 bottom-0 left-0 lg:top-0 lg:left-0 bg-gradient-to-t lg:bg-gradient-to-r from-neutral-800 to-transparent lg:w-1/2 lg:h-full w-full h-3/4"></div>
        <div v-if="!inArticle" class="absolute -translate-y-full z-20 font-bold top-[65%]    lg:top-[50%]  w-full lg:-translate-y-1/2 left-[50%] lg:left-0 -translate-x-1/2 lg:translate-x-0 lg:ml-32 lg:w-1/3 text-left">
          <h1 v-if="heroText !== ''" class="text-subTitle 2xl:text-[4.4em] xl:text-[4em] text-4xl text-center lg:text-left w-full lg:text-[2.2em] xl:leading-[1.4em] lg:leading-[1em] text-white mb-6">{{ heroText }}</h1>
          <p class="text-white xl:text-[1.5em] text-xl text-center lg:text-left w-full lg:text-[12px] xl:leading-[1.2em] lg:leading-[1em] font-normal" v-if="heroSub" >{{heroSub}}</p>
          <div v-if="heroDesc !== ''" class="xl:text-[2em] text-2xl text-center lg:text-left w-full lg:text-[1.5em] xl:leading-[1.2em] lg:leading-[1em] text-white my-6">{{ heroDesc }}</div>
                <a target="_blank"  v-if="heroBtnText !== ''" class="rounded-2xl mt-5 bg-[#C73B0A] block w-max mx-auto lg:mx-0 shadow-xl lg:mr-auto text-white xl:text-[1.2em] lg:text-[1.5em] 3xl:mx-10 py-[15px] px-[50px] text-[1.2em]" :href="heroLink">{{ heroBtnText }}</a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="h-[120px]"></div>
    </template>
    <div v-if="inArticle" class="mt-10 mx-10">
      <h1 v-if="heroText !== ''" class="text-2xl lg:text-3xl">{{ heroText }}</h1>
      <div v-if="heroDesc !== ''" class="text-lg lg:text-2xl my-4 text-main-grey opacity-80">{{ heroDesc }}</div>
    </div>
  </section>
</template>

<script>
import SingleImage from '@/components/SingleImage'

export default {
  name: 'HistoryOfView',
  components: {
    SingleImage
  },
  props: {
    heroSrc: {
      type: String,
      default: ''
    },
    heroSub: {
      type: String,
      default: ''
    },
    heroText: {
      type: String,
      default: ''
    },
    heroDesc: {
      type: String,
      default: ''
    },
    inArticle: {
      type: Boolean,
      default: false
    },
    heroBtnText: {
      type: String,
      default: ''
    },
    heroLink: {
      type: String,
      default: ''
    },
    empty: {
      type: Boolean,
      default: false
    }
  }
}
</script>
