<template>
  <div class="loading">
     <picture>
        <img  src="https://app.paguyuban-kualatungkal.or.id/storage/app/media/logo/main-logo.png">
     </picture>
    <h1>Loading...</h1>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>
